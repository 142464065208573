import { createI18n } from 'vue-i18n'
import en from '../locales/en.json'
import fr from '../locales/fr.json'

export const SUPPORT_LOCALES = ['en', 'fr']

type MessageSchema = typeof fr

export const i18n = createI18n<[MessageSchema], 'fr' | 'en'>({
  legacy: false,
  locale: 'fr',
  fallbackLocale: 'en',
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    en,
    fr,
  },
})
