<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import useFormProgression from '../../composables/useFormProgression'
import BaseLoader from '../../components/BaseLoader/index.vue'
import FormError from '../../components/FormError/index.vue'

defineOptions({
  name: 'FormPage',
})

const router = useRouter()
const route = useRoute()
const formId = route.params.formId as string
const facilityId = route.query.facilityId as string
const practitionerId = route.query.practitionerId as string

const {
  createFormProgression,
  createFormProgressionError,
  onCreateFormProgressionDone,
} = useFormProgression()

createFormProgression({
  formId,
  facilityId,
  practitionerId,
})

onCreateFormProgressionDone(({ data }) => {
  const formProgressionId = data.formProgressionCreate.id

  router.replace({
    name: 'FormProgression',
    params: {
      formProgressionId,
    },
  })
})
</script>

<template>
  <FormError v-if="createFormProgressionError" />
  <BaseLoader v-else />
</template>
