import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Form from '../pages/Form/index.vue'
import FormProgression from '../pages/FormProgression/index.vue'
import NotFound from '../pages/NotFound/index.vue'

export const routes: RouteRecordRaw[] = [
  {
    name: 'FormProgression',
    path: '/p/:formProgressionId/',
    component: FormProgression,
  },
  {
    name: 'Form',
    path: '/:formId/new',
    component: Form,
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})
