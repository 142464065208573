import { App } from 'vue'
import * as Sentry from '@sentry/vue'
import config from '../config'
import { Router } from 'vue-router'

export function initSentry(app: App, router: Router) {
  const tracePropagationTargets: Array<string | RegExp> = []

  if (config.sentry.tracePropagationTargets != null) {
    for (const origin of config.sentry.tracePropagationTargets) {
      tracePropagationTargets.push(origin)
    }
  }

  tracePropagationTargets.push(/^\//)

  Sentry.init({
    app,
    integrations: [Sentry.browserTracingIntegration({ router })],
    logErrors: true,
    enabled: config.sentry.enabled,
    dsn: config.sentry.dsn,
    tracesSampleRate: config.sentry.tracesSampleRate,
    environment: config.sentry.environment,
    release: config.app.version,
  })
}
