import {
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  isDate,
  isValid,
  parse,
  startOfDay,
} from 'date-fns'

export default function isDateDifferenceLessThan(
  date: Date | string,
  unit: string,
  value: number,
): boolean {
  const now = startOfDay(new Date())
  const formattedDate = startOfDay(
    isDate(date) ? date : parse(date, 'dd/MM/yyyy', now),
  )

  if (!isValid(formattedDate)) {
    return false
  }

  switch (unit) {
    case 'days':
      return differenceInDays(now, formattedDate) < value
    case 'months':
      return differenceInMonths(now, formattedDate) < value
    case 'years':
      return differenceInYears(now, formattedDate) < value
    default:
      return false
  }
}
