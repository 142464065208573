import { computed, ComputedRef, inject, provide, readonly, Ref, ref } from 'vue'

const myStepper = Symbol()

export default function useStep(stepsCount: ComputedRef<number> | Ref<number>) {
  let step = ref(0)

  const goTo = (stepIndex: number) => {
    if (stepIndex >= 0 && stepIndex < stepsCount.value) {
      step.value = stepIndex

      return true
    }

    return false
  }

  const nextStep = () => {
    if (step.value < stepsCount.value - 1) {
      step.value++

      return true
    }

    return false
  }

  const previousStep = () => {
    if (step.value > 0) {
      step.value--

      return true
    }

    return false
  }

  const provideGlobalStepData = () => {
    provide(myStepper, step)
  }

  const resetStep = () => {
    step.value = 0
  }

  const useGlobalStepData = () => {
    const globalData = inject<Ref<number>>(myStepper)

    if (globalData) {
      // eslint-disable-next-line vue/no-ref-as-operand
      step = globalData
    }
  }

  return {
    step: computed(() => step.value),
    stepsCount: readonly(stepsCount),
    goTo,
    nextStep,
    previousStep,
    provideGlobalStepData,
    resetStep,
    useGlobalStepData,
  }
}
