import { FormStep } from '../composables/useFormStep'
import replaceTextKeysWithValues, {
  ReplacementMap,
} from './replaceTextKeysWithValues'

export default function getFormattedLocales(
  locales: FormStep['locales'],
  replacementMap: ReplacementMap,
): FormStep['locales'] {
  const formattedLocales = replaceTextKeysWithValues(
    JSON.stringify(locales),
    replacementMap,
  )

  return JSON.parse(formattedLocales)
}
