<script setup lang="ts">
import { computed, defineAsyncComponent, ref, shallowRef, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import getFormattedLayout from '../../helpers/getFormattedLayout'
import logError from '../../helpers/logError'
import BaseLoader from '../BaseLoader/index.vue'
import BaseSlider from '../BaseSlider/index.vue'
import FormError from '../FormError/index.vue'
import type { FormStep } from '../../composables/useFormStep'
import useFormAction from '../../composables/useFormAction'
import useFormProgression from '../../composables/useFormProgression'
import getFormattedLocales from '../../helpers/getFormattedLocales'
import useFormStep from '../../composables/useFormStep'

defineOptions({
  name: 'TheFormStep',
})

const { t } = useI18n()
const { formProgression } = useFormProgression()
const { init: initFormActions, save, saveError, saveLoading } = useFormAction()
const {
  formStep,
  goToFormStepId,
  previousFormStep,
  nextFormStep,
  useGlobalFormStepData,
} = useFormStep()

useGlobalFormStepData()

const stepError = ref(false)
const stepLoading = ref(false)

const step = computed(() => formStep.value)
const stepOrder = ref(1)
const stepLayout = shallowRef<unknown>(null)
const stepLocales = shallowRef<FormStep['locales']>(formStep.value?.locales)

watch(
  formStep,
  async (newStep, oldStep) => {
    if (newStep && newStep.id !== oldStep?.id) {
      try {
        stepLoading.value = true

        // init the step layout
        stepOrder.value = newStep.order

        const layout = getFormattedLayout(formStep.value)

        if (newStep.layout !== oldStep?.layout) {
          stepLayout.value = defineAsyncComponent({
            loader: () => import(`../${layout}/index.vue`),
            loadingComponent: BaseLoader,
            onError: (error) => {
              stepError.value = true
              logError(error)
            },
          })
        }

        // init the step locales
        const practitionerName = formProgression.value?.practitionerName ?? ''
        const initDataWithKeys = await initFormActions(newStep.init)

        stepLocales.value = getFormattedLocales(formStep.value?.locales, {
          ...initDataWithKeys,
          practitionerName,
          patientPractitioner: practitionerName
            ? t('common.doctorAbbreviation', { practitionerName })
            : t('common.doctor'),
        })
      } catch (error) {
        stepError.value = true
        logError(error)
      } finally {
        stepLoading.value = false
      }
    }
  },
  { immediate: true },
)
</script>

<template>
  <BaseSlider :current-step="stepOrder">
    <Transition name="delayed-fade-in">
      <BaseLoader v-if="saveLoading || stepLoading" />
      <FormError
        v-else-if="saveError || stepError"
        :retry="true"
        @retry="save"
      />
      <component
        :is="stepLayout"
        v-else-if="step"
        :key="step.id"
        :answer="step.answer"
        :class="$style.layout"
        :locales="stepLocales"
        :optional="step.optional"
        :properties="step.properties"
        @go-to.once="goToFormStepId($event)"
        @next.once="nextFormStep"
        @previous.once="previousFormStep"
        @save.once="
          save({
            ...$event,
            step,
          })
        "
      />
    </Transition>
  </BaseSlider>
</template>

<style module lang="postcss">
.layout {
  display: flex;
  flex-direction: column;
  gap: var(--spacings-xlarge);

  & > :global(button) {
    align-self: center;
  }

  @media (max-width: 600px) {
    gap: var(--spacings-large);
  }
}
</style>
