import { gql } from 'graphql-tag'

export const SAVE_FORM_ANSWER = gql`
  mutation SAVE_FORM_ANSWER(
    $answer: String!
    $formProgressionId: ID!
    $stepId: String!
  ) {
    formAnswerSave(
      input: {
        answer: $answer
        formProgressionId: $formProgressionId
        stepId: $stepId
      }
    ) {
      id
      answer
      formProgressionId
      stepId
    }
  }
`
