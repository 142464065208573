import { gql } from 'graphql-tag'

export const CREATE_FORM_PROGRESSION = gql`
  mutation CREATE_FORM_PROGRESSION(
    $formId: ID!
    $facilityId: ID!
    $practitionerId: ID
  ) {
    formProgressionCreate(
      input: {
        formId: $formId
        facilityId: $facilityId
        practitionerId: $practitionerId
      }
    ) {
      id
    }
  }
`
