<script setup lang="ts">
import BaseStepper from '../BaseStepper/index.vue'
import { watch, ref } from 'vue'

defineOptions({
  name: 'BaseSlider',
})

interface Props {
  currentStep?: number
  stepsCount?: number
  showStepper?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  currentStep: 0,
  stepsCount: 1,
  showStepper: false,
})

const direction = ref<'right' | 'left'>('right')

watch(
  () => props.currentStep,
  (val, oldVal) => {
    if (val > oldVal) {
      direction.value = 'right'
    } else {
      direction.value = 'left'
    }
  },
)

const afterLeave = () => {
  window.scroll({
    top: 0,
    left: 0,
  })
}
</script>

<template>
  <div :class="$style.wrapper">
    <transition-group
      tag="div"
      :class="[$style.content, $style[`content--${direction}`]]"
      name="slide"
      @after-leave="afterLeave"
    >
      <div
        v-for="id in [currentStep]"
        :key="`slide-${id}`"
        :class="$style.slide"
      >
        <slot></slot>
      </div>
    </transition-group>

    <BaseStepper
      v-if="showStepper && stepsCount > 1"
      :current-step="currentStep"
      :steps-count="stepsCount"
      type="slider"
    />
  </div>
</template>

<style lang="postcss" module>
.wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacings-large);
}

.slide {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;

  &:first-child {
    position: relative;
    z-index: 2;
  }
}

.content {
  & :global(.slide-leave-active) {
    transition:
      transform 0.4s ease,
      opacity 0.3s ease;
  }
  & :global(.slide-enter-active) {
    transition:
      transform 0.6s ease 0.4s,
      opacity 0.3s ease 0.4s;
  }
}

.content--right {
  & :global(.slide-enter-from) {
    transform: translate(80px, 0);
    opacity: 0;
  }

  & :global(.slide-leave-to) {
    transform: translate(-80px, 0);
    opacity: 0;
  }
}

.content--left {
  & :global(.slide-enter-from) {
    transform: translate(-80px, 0);
    opacity: 0;
  }

  & :global(.slide-leave-to) {
    transform: translate(80px, 0);
    opacity: 0;
  }
}
</style>
