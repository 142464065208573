/* eslint-disable security/detect-unsafe-regex */
import get from 'lodash/get'
import isFinite from 'lodash/isFinite'
import isString from 'lodash/isString'

export interface ReplacementMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

export default function replaceTextKeysWithValues(
  data: string,
  replacementMap: ReplacementMap,
): string {
  let formattedData = data

  // Get all expressions with {{...}} format inside locales. Ex: {{scores.episodeStartScore}}
  const textKeys = [...formattedData.matchAll(/\{{([^}[]*)}}/gm)]

  textKeys.forEach((key) => {
    let value = ''
    const textKey = key[1].replaceAll(' ', '')
    const isFunction =
      /^@\.([a-zA-Z0-9]{5,30})\((?:[a-zA-Z0-9.]{1,50})(?:,[a-zA-Z0-9.]{1,50}?){0,4}\)$/.test(
        textKey,
      )

    if (isFunction) {
      const functionName = textKey.split('@.')[1].split('(')[0]

      if (functionName === 'signConditional') {
        /**
         * @function @.signConditional(value, prefixKey)
         * @description Returns an i18n key based on the sign of a numerical value.
         *
         * @param {number} value - The numerical value to check.
         * @param {string} prefixKey - The prefix for generating the i18n key.
         * @returns {string} -
         *    `@:prefixKey_positive` if value is positive,
         *    `@:prefixKey_negative` if negative, or
         *    `@:prefixKey_neutral` if zero.
         */

        const params = textKey
          .replace(`@.${functionName}(`, '')
          .replace(')', '')
          .split(',')

        const conditionValue = parseFloat(
          get(replacementMap, params[0], params[0]),
        )
        const prefixKey = params[1]

        if (
          params.length === 2 &&
          isFinite(conditionValue) &&
          isString(prefixKey)
        ) {
          const sign =
            conditionValue === 0
              ? 'neutral'
              : conditionValue < 0
                ? 'negative'
                : 'positive'

          value = `@:${prefixKey}_${sign}`
        }
      }
    } else {
      value = get(replacementMap, textKey, '')
    }

    formattedData = formattedData.replace(key[0], value)
  })

  return formattedData
}
