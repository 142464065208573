import merge from 'lodash/merge'

export interface GraphQLConfig {
  endpoint: string
}

export interface AppConfig {
  version: string
}

export interface SentryConfig {
  enabled?: boolean
  dsn?: string
  tracesSampleRate?: number
  environment?: string
  release?: string
  tracePropagationTargets?: string[]
}

export interface Config {
  graphql: GraphQLConfig
  app: AppConfig
  sentry: SentryConfig
}

function getNumber(
  ...envValues: Array<string | boolean | undefined>
): number | undefined {
  for (const envValue of envValues) {
    if (envValue != null && typeof envValue === 'string') {
      const parsed = parseFloat(envValue)

      if (!isNaN(parsed) && parsed !== Infinity) {
        return parsed
      }
    }
  }
}

function getBoolean(
  ...envValues: Array<string | boolean | undefined>
): boolean | undefined {
  for (const envValue of envValues) {
    if (envValue != null) {
      if (typeof envValue === 'boolean') {
        return envValue
      } else {
        return envValue.toLowerCase() === 'true'
      }
    }
  }
}

function getString(
  ...envValues: Array<string | boolean | undefined>
): string | undefined {
  for (const envValue of envValues) {
    if (envValue != null && typeof envValue === 'string') {
      return envValue
    }
  }
}

function getStringList(
  ...envValues: Array<string | boolean | undefined>
): string[] | undefined {
  for (const envValue of envValues) {
    if (envValue != null && typeof envValue === 'string') {
      return envValue.split(',').map((s) => s.trim())
    }
  }
}

const config: Config = {
  graphql: {
    endpoint:
      getString(
        `${import.meta.env.PT_FORM_API_ENDPOINT}/graphql`,
        `${import.meta.env.PT_ALL_API_ENDPOINT}/graphql`,
      ) ?? 'http://localhost:4000/graphql',
  },
  app: {
    version: getString(import.meta.env.PT_FORM_VERSION) ?? '0.0.0-dev',
  },
  sentry: {
    enabled:
      getBoolean(
        import.meta.env.PT_FORM_SENTRY_ENABLED,
        import.meta.env.PT_ALL_SENTRY_ENABLED,
      ) ?? false,
    dsn: getString(import.meta.env.PT_FORM_SENTRY_DSN),
    environment: getString(
      import.meta.env.PT_FORM_SENTRY_ENVIRONMENT,
      import.meta.env.PT_ALL_SENTRY_ENVIRONMENT,
      import.meta.env.PT_ENVIRONMENT,
    ),
    release: getString() ?? '0.0.0-dev',
    tracesSampleRate: getNumber(
      import.meta.env.PT_FORM_SENTRY_TRACES_SAMPLE_RATE,
      import.meta.env.PT_ALL_SENTRY_TRACES_SAMPLE_RATE,
    ),
    tracePropagationTargets: getStringList(
      import.meta.env.PT_FORM_SENTRY_TRACING_ORIGINS,
    ),
  },
}

merge(config, window.config)
export default config
