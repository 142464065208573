import { gql } from 'graphql-tag'

export const GENERATE_CONSENT = gql`
  mutation GENERATE_CONSENT($input: GenerateConsentInput!) {
    consentGenerate(input: $input) {
      formProgressionId
      hasConsented
      type
    }
  }
`
