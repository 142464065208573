import { FormProperty, FormPropertyType } from '../../composables/useFormStep'

interface propertyCount {
  barGaugeCount: number
  buttonCount: number
  confirmCount: number
  inputCount: number
  markdownCount: number
  radioCount: number
  rangeCount: number
  rowCount: number
  invalidCount: number
  totalCount: number
}

export default function getPropertyCount(
  properties: FormProperty[],
): propertyCount {
  let buttonCount = 0
  let confirmCount = 0
  let inputCount = 0
  let radioCount = 0
  let markdownCount = 0
  let barGaugeCount = 0
  let rangeCount = 0
  let rowCount = 0
  let invalidCount = 0
  let totalCount = properties.length

  function countProperties(properties: FormProperty[]): void {
    properties.forEach(({ type, properties: propertyProperties }) => {
      if (type === FormPropertyType.ROW_PROPERTIES) {
        rowCount++

        if (propertyProperties?.length) {
          totalCount += propertyProperties.length
          countProperties(propertyProperties)
        }
      } else if (
        type === FormPropertyType.BUTTON ||
        type === FormPropertyType.BUTTON_SECONDARY
      ) {
        buttonCount++
      } else if (type === FormPropertyType.CONFIRM) {
        confirmCount++
      } else if (
        type === FormPropertyType.EMAIL ||
        type === FormPropertyType.NUMBER ||
        type === FormPropertyType.TEL ||
        type === FormPropertyType.TEXT ||
        type === FormPropertyType.DATE_PICKER
      ) {
        inputCount++
      } else if (type === FormPropertyType.RADIO) {
        radioCount++
      } else if (type === FormPropertyType.RANGE) {
        rangeCount++
      } else if (type === FormPropertyType.MARKDOWN) {
        markdownCount++
      } else if (type === FormPropertyType.BAR_GAUGE) {
        barGaugeCount++
      } else {
        invalidCount++
      }
    })
  }

  countProperties(properties)

  return {
    barGaugeCount,
    buttonCount,
    confirmCount,
    inputCount,
    invalidCount,
    markdownCount,
    radioCount,
    rangeCount,
    rowCount,
    totalCount,
  }
}
