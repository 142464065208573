import { gql } from 'graphql-tag'

export const GET_FORM_SCORE_CHANGE = gql`
  query GET_FORM_SCORE_CHANGE(
    $episodeStart: Int!
    $episodeEnd: Int!
    $formProgressionId: ID!
    $scoreType: FormScoreType!
  ) {
    formScoreChange(
      episodeStart: $episodeStart
      episodeEnd: $episodeEnd
      formProgressionId: $formProgressionId
      scoreType: $scoreType
    ) {
      episodeStartScore
      episodeEndScore
      percentChange
      scoreDiff
      scoreType
    }
  }
`
