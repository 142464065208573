import kebabCase from 'lodash/kebabCase'

interface IconComponents {
  // eslint-disable-next-line
  [index: string]: any
}

interface Icons {
  iconComponents: IconComponents
  iconKeys: string[]
}

// Generate icon name list from Icons directory
export default function getIcon(): Icons {
  const iconComponents: IconComponents = {}
  const iconKeys: string[] = []

  const iconList = import.meta.glob('../../components/BaseIcon/icons/*.vue')

  for (const path in iconList) {
    const iconName = kebabCase(
      path?.split('/icons/')?.pop()?.split('Icon.vue')[0],
    )

    // eslint-disable-next-line security/detect-object-injection
    iconComponents[iconName] = iconList[path]
    iconKeys.push(iconName)
  }

  return {
    iconComponents,
    iconKeys,
  }
}
