import { RouteParamsRaw } from 'vue-router'
import replaceTextKeysWithValues, {
  ReplacementMap,
} from '../replaceTextKeysWithValues'

export default function getFormattedRouteParams(
  params: RouteParamsRaw,
  replacementMap: ReplacementMap,
): RouteParamsRaw {
  const formattedRouteParams = replaceTextKeysWithValues(
    JSON.stringify(params),
    replacementMap,
  )

  return JSON.parse(formattedRouteParams)
}
