import { gql } from 'graphql-tag'

export const UPDATE_FORM_PROGRESSION = gql`
  mutation UPDATE_FORM_PROGRESSION($id: ID!, $nextStepId: String!) {
    formProgressionUpdate(id: $id, nextStepId: $nextStepId) {
      id
      nextStepId
      patientId
    }
  }
`
