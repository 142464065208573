<script setup lang="ts">
import { computed } from 'vue'

defineOptions({
  name: 'BaseStepper',
})

interface Props {
  currentStep: number
  stepsCount: number
  type?: 'story' | 'slider'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'story',
})

const stepList = computed(() =>
  [...Array(props.stepsCount).keys()].map((i) => {
    return {
      id: `${props.type}Step-${i}`,
      isActive: props.currentStep > i,
      isCurrentActive: props.currentStep === i,
    }
  }),
)
</script>

<template>
  <transition name="fade" appear>
    <div :class="$style.wrapper">
      <div
        v-for="step in stepList"
        :key="step.id"
        :class="[
          $style[`${type}Step`],
          { [$style[`${type}Step--active`]]: step.isActive },
          { [$style[`${type}Step--currentActive`]]: step.isCurrentActive },
        ]"
      />
    </div>
  </transition>
</template>

<style lang="postcss" module>
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:global(.fade-enter-active) {
    transition-delay: 0.4s;
  }
}

.storyStep {
  flex: 1;
  height: 4px;
  margin: 1.5px;
  position: relative;
  background-color: var(--layout-border-color);
  border-radius: 2px;

  &:before {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2px;
    background-color: var(--global-color-primary);
    transition: width 0.5s ease 0.2s;
  }

  @media (max-width: 1024px) {
    border-radius: 0;
    margin: 0;

    &:before {
      border-radius: 0;
    }

    &:first-child,
    &:first-child:before {
      border-radius: 2px 0 0 2px;
    }

    &:last-child,
    &:last-child:before {
      border-radius: 0 2px 2px 0;
    }
  }
}

.storyStep--active:before,
.storyStep--currentActive:before {
  width: 100%;
}

.sliderStep,
.sliderStep--active {
  width: 8px;
  height: 8px;
  margin: 4px;
  position: relative;
  border: 1px solid var(--layout-border-color-2);
  background-color: white;
  border-radius: 50%;
  overflow: hidden;
}

.sliderStep--currentActive {
  background-color: var(--text-color);
  border-color: var(--text-color);
}
</style>
