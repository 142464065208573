<script setup lang="ts">
import { computed, defineAsyncComponent, useCssModule } from 'vue'
import getIcons from '../../helpers/getIcons'

defineOptions({
  name: 'BaseIcon',
})

interface Props {
  name: string
  size?: number
  color?: 'default' | 'primary' | 'error' | 'white'
}

const props = withDefaults(defineProps<Props>(), {
  size: 30,
  color: undefined,
})

const style = useCssModule()

const iconColor = computed(() => props.color && style[`${props.color}Color`])

const { iconComponents, iconKeys } = getIcons()
const iconComponent = computed<unknown>(() =>
  iconKeys.includes(props.name)
    ? defineAsyncComponent(iconComponents[props.name])
    : null,
)
</script>

<template>
  <component
    :is="iconComponent"
    :height="size"
    :width="size"
    :style="iconColor && `color: ${iconColor};`"
    role="img"
  />
</template>

<style lang="postcss" module>
:export {
  defaultColor: var(--text-color);
  primaryColor: var(--global-color-primary);
  errorColor: var(--global-color-error);
  whiteColor: white;
}
</style>
