<script setup lang="ts">
import { computed, inject } from 'vue'
import PromTimeLogo from './logos/PromTimeLogo/index.vue'
import CarpalTunnelLogo from './logos/CarpalTunnelLogo/index.vue'
import PromBirthLogo from './logos/PromBirthLogo/index.vue'
import PromCatLogo from './logos/PromCatLogo/index.vue'
import { ModuleSlugManager } from '../../pages/FormProgression/index.vue'

defineOptions({
  name: 'TheLogo',
})

const moduleSlugManager = inject<ModuleSlugManager>('moduleSlugManager')
const slug = computed(() => moduleSlugManager?.moduleSlug.value)
</script>

<template>
  <CarpalTunnelLogo
    v-if="slug === 'carpal-tunnel'"
    :class="$style.customLogo"
  />
  <PromBirthLogo v-else-if="slug === 'maternity'" :class="$style.customLogo" />
  <PromCatLogo v-else-if="slug === 'promcat'" :class="$style.customLogo" />
  <PromTimeLogo v-else :class="$style.defaultLogo" />
</template>

<style module lang="postcss">
.defaultLogo {
  width: 160px;
  flex-shrink: 0;

  @media (max-width: 600px) {
    width: 142px;
  }
}

.customLogo {
  width: 300px;
  flex-shrink: 0;

  @media (max-width: 600px) {
    width: 260px;
  }
}
</style>
