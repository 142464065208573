import { FormProgression } from '../../composables/useFormProgression'
import { FormStep } from '../../composables/useFormStep'

export default function getFormattedFormSteps(
  formProgression: FormProgression | null,
): FormStep[] {
  return (
    formProgression?.formSchema?.schema.map((step) => {
      const answer = formProgression?.formAnswers.find(
        ({ stepId }) => stepId === step.id,
      )?.answer

      return {
        ...step,
        answer,
        optional: !!step?.optional,
      }
    }) ?? []
  )
}
