<script setup lang="ts">
import { computed, inject, watch } from 'vue'
import isNil from 'lodash/isNil'
import BaseLoader from '../../components/BaseLoader/index.vue'
import FormError from '../../components/FormError/index.vue'
import TheFormStep from '../../components/TheFormStep/index.vue'
import useFormProgression from '../../composables/useFormProgression'
import useFormStep from '../../composables/useFormStep'

export interface ModuleSlugManager {
  moduleSlug: { value: string }
  updateModuleSlug: (newValue: string) => void
}

defineOptions({
  name: 'FormProgressionPage',
})

const moduleSlugManager = inject<ModuleSlugManager>('moduleSlugManager')

const {
  formProgression,
  formProgressionError,
  formProgressionLoading,
  formSteps,
} = useFormProgression('cache-and-network')

const { goToFormStepId, provideGlobalFormStepData, resetFormStep } =
  useFormStep(formSteps)

provideGlobalFormStepData()

watch(formProgression, (newFormProgression, oldFormProgression) => {
  if (newFormProgression && newFormProgression.id !== oldFormProgression?.id) {
    if (!isNil(moduleSlugManager)) {
      moduleSlugManager.updateModuleSlug(newFormProgression.moduleSlug)
    }

    goToFormStepId(newFormProgression.nextStepId) || resetFormStep()
  }
})

const isFormProgressionExpired = computed(
  () =>
    formProgression.value?.expireAt &&
    new Date() > new Date(formProgression.value.expireAt),
)

const formProgressionErrorType = computed(() =>
  isFormProgressionExpired.value ? 'expiredDate' : undefined,
)
</script>

<template>
  <BaseLoader v-if="formProgressionLoading" />
  <FormError
    v-else-if="!!formProgressionError || isFormProgressionExpired"
    :type="formProgressionErrorType"
  />
  <div v-else>
    <TheFormStep />
  </div>
</template>
