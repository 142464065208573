<script setup lang="ts">
import { computed, useSlots } from 'vue'

defineOptions({
  name: 'BaseButton',
})

interface Props {
  color?: 'primary' | 'secondary' | 'tertiary'
  type?: 'button' | 'submit'
  size?: 'small' | 'medium' | 'large'
  disabled?: boolean
}

withDefaults(defineProps<Props>(), {
  color: 'primary',
  type: 'button',
  size: 'medium',
  disabled: false,
})

const slots = useSlots()

const iconChecker = computed(() => {
  const slotArray = slots?.default?.()

  if (slotArray) {
    return slotArray.map((el) => {
      return typeof el.type === 'object'
    })
  }

  return []
})

const leftIcon = computed(() => {
  return iconChecker.value.length > 1 && iconChecker.value[0]
})

const rightIcon = computed(() => {
  const last = iconChecker.value.length - 1

  // eslint-disable-next-line security/detect-object-injection
  return iconChecker.value.length > 1 && iconChecker.value[last]
})
</script>

<template>
  <button
    :class="[
      $style.wrapper,
      $style[`wrapper--${color}`],
      $style[`wrapper--${size}`],
      { [$style['wrapper--leftIcon']]: leftIcon },
      { [$style['wrapper--rightIcon']]: rightIcon },
      { [$style['wrapper--disabled']]: disabled },
    ]"
    :type="type"
  >
    <slot></slot>
  </button>
</template>

<style lang="postcss" module>
.wrapper {
  border: 1px solid transparent;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  transition: all 0.2s ease;
}

.wrapper--medium {
  padding: var(--spacings-medium) var(--spacings-large);
  font-size: 1.8rem;
  font-weight: 600;

  & svg {
    margin: 0 10px;
  }
}

.wrapper--small {
  padding: var(--spacings-xxsmall) var(--spacings-xsmall);
  font-size: 2.2rem;
  font-weight: 400;

  & svg {
    margin: 0 10px;
  }
}

.wrapper--primary {
  color: white;
  border-color: var(--global-color-primary);
  background-color: var(--global-color-primary);
  box-shadow: 0px 1px 2px rgba(74, 100, 124, 0.16);

  &:hover {
    background-color: var(--button-primary-hover);
  }

  &:active {
    background-color: var(--button-primary-active);
  }
}

.wrapper--secondary {
  color: var(--text-color);
  border-color: var(--layout-border-color-2);
  background-color: white;
  box-shadow: 0px 1px 2px rgba(74, 100, 124, 0.16);

  &:hover {
    background-color: var(--button-secondary-hover);
  }

  &:active {
    background-color: var(--button-secondary-active);
  }
}

.wrapper--tertiary {
  color: var(--text-color);
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}

.wrapper--disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.wrapper--leftIcon {
  &.wrapper--small svg:first-child {
    margin: 0 10px 0 -6px;
  }

  &.wrapper--medium svg:first-child {
    margin: 0 10px 0 -10px;
  }
}

.wrapper--rightIcon {
  &.wrapper--small svg:last-child {
    margin: 0 -6px 0 10px;
  }

  &.wrapper--medium svg:last-child {
    margin: 0 -10px 0 10px;
  }
}
</style>
