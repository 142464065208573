import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import isEqual from 'lodash/isEqual'
import type { FormSkipIf } from '../composables/useFormStep'
import isDateDifferenceGreaterThan from './isDateDifferenceGreaterThan'
import isDateDifferenceLessThan from './isDateDifferenceLessThan'

export default function isStepSkipped(
  condition: FormSkipIf,
  answer?: string,
): boolean {
  const { value, rule } = condition

  switch (rule?.name) {
    case 'isDateDifferenceGreaterThan': {
      const unit = rule.args?.unit
      const duration = rule.args?.value

      return (
        isString(answer) &&
        isString(unit) &&
        isNumber(duration) &&
        isDateDifferenceGreaterThan(answer, unit, duration)
      )
    }

    case 'isDateDifferenceLessThan': {
      const unit = rule.args?.unit
      const duration = rule.args?.value

      return (
        isString(answer) &&
        isString(unit) &&
        isNumber(duration) &&
        isDateDifferenceLessThan(answer, unit, duration)
      )
    }

    default: {
      const expectedAnswer = value || rule?.args?.value

      return !isNil(expectedAnswer) && isEqual(answer, expectedAnswer)
    }
  }
}
