import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core'
// Import from /core to workaround typing issue : https://github.com/johnsoncodehk/volar/discussions/592#discussioncomment-1569072
import config from '../../config'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: config.graphql.endpoint,
})

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    FormScoreChange: {
      fields: {
        episodeStartScore: {
          read(episodeStartScore) {
            return episodeStartScore ?? '-'
          },
        },
        episodeEndScore: {
          read(episodeEndScore) {
            return episodeEndScore ?? '-'
          },
        },
      },
    },
  },
})

// Create the apollo client
export default new ApolloClient({
  name: 'promtime-form-client',
  version: '1.0',
  link: httpLink,
  cache,
})
