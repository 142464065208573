import { createApp, provide, h } from 'vue'
import App from './App.vue'
import apolloClient from './clients/apollo'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { initSentry } from './plugins/sentry'
import { router } from './router'
import { i18n } from './plugins/i18n'
import Vue3TouchEvents, { Vue3TouchEventsOptions } from 'vue3-touch-events'

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

initSentry(app, router)
app.use(router)
app.use(i18n)
// Temporary workaround for a type bug in Vue3TouchEvents.
// Replace with app.use(Vue3TouchEvents) once the bug is fixed.
app.use<Vue3TouchEventsOptions>(Vue3TouchEvents, {})
app.mount('#app')
