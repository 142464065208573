import { useRouter } from 'vue-router'
import { readonly, ref } from 'vue'
import { useApolloClient, useMutation } from '@vue/apollo-composable'
import { GET_RELATED_FORM_PROGRESSION } from '../../graphql/queries/GET_RELATED_FORM_PROGRESSION'
import { SAVE_FORM_ANSWER } from '../../graphql/mutations/SAVE_FORM_ANSWER'
import logError from '../../helpers/logError'
import useFormStep, { FormStep } from '../useFormStep'
import useFormProgression from '../useFormProgression'
import { GENERATE_CONSENT } from '../../graphql/mutations/GENERATE_CONSENT'
import { CREATE_PATIENT } from '../../graphql/mutations/CREATE_PATIENT'
import { GET_FORM_SCORE_CHANGE } from '../../graphql/queries/GET_FORM_SCORE_CHANGE'
import { FetchResult } from '@apollo/client'
import replaceTextKeysWithValues from '../../helpers/replaceTextKeysWithValues'
import getFormattedRouteParams from '../../helpers/getFormattedRouteParams'
import { GET_FORM_PROGRESSION } from '../../graphql/queries/GET_FORM_PROGRESSION'
import { isNil, isString } from 'lodash'

enum FormRedirectionType {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export interface FormRedirection {
  name: string
  params: {
    [key: string]: string
  }
  replace: boolean
  type: FormRedirectionType
  url: string
}

export interface FormAction {
  query?: string
  mutation?: string
  args?: {
    [key: string]: string | boolean | number
  }
  key?: string
  onError?: {
    goTo: string
  }
  onSuccess?: {
    goTo?: string
    redirectTo?: FormRedirection
  }
}

export interface SaveAction {
  action?: FormAction | null
  step?: FormStep
  value?: string | { [key: string]: string }
}

export default function useFormAction() {
  // Use apollo client to get query promise
  const { client } = useApolloClient()

  const router = useRouter()
  const { formProgression, updateFormProgression, onFetchFormProgressionDone } =
    useFormProgression()

  let formProgressionId = formProgression.value?.id

  onFetchFormProgressionDone(() => {
    formProgressionId = formProgression.value?.id
  })

  const { useGlobalFormStepData, getNextFormStepId, goToFormStepId } =
    useFormStep()

  useGlobalFormStepData()

  let lastFailedSaveAction: SaveAction | null
  const saveError = ref(false)
  const saveLoading = ref(false)

  const { mutate: saveFormAnswer } = useMutation(SAVE_FORM_ANSWER)
  const { mutate: generateConsent } = useMutation(GENERATE_CONSENT)
  const { mutate: createFormPatient } = useMutation(CREATE_PATIENT)

  const mutate = async (
    action: SaveAction['action'],
    stepId?: string,
    value?: SaveAction['value'],
  ) => {
    let result: FetchResult | null

    switch (action?.mutation) {
      case 'CREATE_PATIENT_PROMHAND_XP': {
        if (isNil(value) || !isString(value)) {
          return null
        }

        result = await createFormPatient({
          input: {
            ...action.args,
            formProgressionId,
            emrId: value,
          },
        })

        break
      }

      case 'CREATE_PATIENT': {
        if (isNil(value) || isString(value)) {
          return null
        }

        const { firstname, lastname, tel } = value

        result = await createFormPatient({
          input: {
            ...action.args,
            formProgressionId,
            firstname,
            lastname,
            tel,
          },
        })

        break
      }

      case 'GENERATE_CONSENT': {
        result = await generateConsent({
          input: {
            ...action.args,
            formProgressionId,
          },
        })

        break
      }

      case 'SAVE_FORM_ANSWER': {
        result = await saveFormAnswer(
          {
            answer: value,
            formProgressionId,
            stepId,
          },
          {
            update: (cache, { data: { formAnswerSave } }) => {
              if (formProgression?.value) {
                const formAnswers = [...formProgression.value.formAnswers]

                const updatedFormAnswerIndex = formAnswers.findIndex(
                  ({ id }) => id === formAnswerSave.id,
                )

                if (updatedFormAnswerIndex === -1) {
                  formAnswers.push(formAnswerSave)
                } else {
                  // eslint-disable-next-line security/detect-object-injection
                  formAnswers[updatedFormAnswerIndex] = formAnswerSave
                }

                cache.writeQuery({
                  query: GET_FORM_PROGRESSION,
                  data: {
                    formProgression: {
                      ...formProgression.value,
                      formAnswers,
                    },
                  },
                  variables: {
                    id: formProgressionId,
                  },
                })
              }
            },
          },
        )

        break
      }

      default: {
        throw new Error(`The mutation ${action?.mutation} does not exist!`)
      }
    }

    return handleRequestSuccess(result, action)
  }

  const get = async (action: SaveAction['action']) => {
    let result: FetchResult | null

    switch (action?.query) {
      case 'GET_FORM_SCORE_CHANGE': {
        result = await client.query({
          query: GET_FORM_SCORE_CHANGE,
          variables: {
            ...action.args,
            formProgressionId,
          },
        })

        break
      }

      case 'GET_RELATED_FORM_PROGRESSION': {
        result = await client.query({
          query: GET_RELATED_FORM_PROGRESSION,
          variables: {
            ...action.args,
            id: formProgressionId,
          },
        })

        break
      }

      default: {
        throw new Error(`The query ${action?.query} does not exist!`)
      }
    }

    return handleRequestSuccess(result, action)
  }

  const handleRequestSuccess = (
    result: FetchResult | null,
    action: SaveAction['action'],
  ) => {
    let resultDataWithKey: FetchResult = {}

    if (result?.data) {
      if (action?.key) {
        const dataKey: string = Object.keys(result.data)[0]

        resultDataWithKey = {
          [action.key]: result.data[dataKey.toString()],
        }
      }

      if (action?.onSuccess) {
        const { goTo, redirectTo } = action.onSuccess

        if (redirectTo) {
          const { name, params, replace, type, url } = redirectTo

          if (type === FormRedirectionType.EXTERNAL) {
            // If the redirect type is 'EXTERNAL', redirect to an external URL
            const formattedUrl = replaceTextKeysWithValues(
              url,
              resultDataWithKey,
            )

            window.location.href = formattedUrl
          } else {
            // If the redirect type is not 'EXTERNAL', navigate to a named route using the router
            const formattedParams = getFormattedRouteParams(
              params,
              resultDataWithKey,
            )

            router.push({
              name,
              params: formattedParams,
              replace,
            })
          }
        } else if (goTo) {
          goToFormStepId(goTo)
        }
      }
    }

    return action?.key ? resultDataWithKey : result
  }

  const init = async (formActions: FormStep['init']) => {
    let resultDataWithKeys: FetchResult = {}

    if (formActions?.length) {
      await Promise.all(
        formActions.map(async function (action) {
          if (action.query || action.mutation) {
            const result = action.query
              ? await get(action)
              : await mutate(action)

            if (action.key) {
              resultDataWithKeys = {
                ...resultDataWithKeys,
                ...result,
              }
            }
          }
        }),
      )
    }

    return resultDataWithKeys
  }

  const save = async (data?: SaveAction) => {
    try {
      if (!data && !lastFailedSaveAction) {
        throw new Error('Invalid save parameters.')
      }

      const action = data?.action ?? lastFailedSaveAction?.action
      const step = data?.step ?? lastFailedSaveAction?.step
      const value = data?.value ?? lastFailedSaveAction?.value

      saveLoading.value = true

      if (step && step.type !== 'CUSTOM' && value && value !== step.answer) {
        await mutate(
          {
            mutation: 'SAVE_FORM_ANSWER',
            args: {},
          },
          step.id,
          value,
        )
      }

      if (action?.mutation) {
        await mutate(action, step?.id, value)
      }

      const formStepId = action?.onSuccess?.goTo || getNextFormStepId()

      await updateFormProgression(formStepId)
      goToFormStepId(formStepId)

      lastFailedSaveAction = null
      saveError.value = false
    } catch (error) {
      if (data) {
        lastFailedSaveAction = data
      }

      saveError.value = true
      logError(error)
    } finally {
      saveLoading.value = false
    }
  }

  return {
    get,
    init,
    mutate,
    save,
    saveError: readonly(saveError),
    saveLoading: readonly(saveLoading),
  }
}
