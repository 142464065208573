<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import BaseIcon from '../BaseIcon/index.vue'
import BaseButton from '../BaseButton/index.vue'
import { computed } from 'vue'

defineOptions({
  name: 'FormError',
})

interface Props {
  type?: 'badRequest' | 'expiredDate' | 'notFound'
  retry?: boolean
}

interface Icon {
  name: 'bug' | 'lock' | 'not-found'
  color: 'default' | 'primary' | 'error' | 'white'
}

const props = withDefaults(defineProps<Props>(), {
  type: 'badRequest',
  retry: false,
})

const emit = defineEmits(['retry'])

const { t } = useI18n()

const error = computed(() => {
  return {
    title: t(`error.${props.type}.title`),
    message: t(`error.${props.type}.message`),
    contact: t(`error.${props.type}.contact`),
  }
})

const icon = computed<Icon>(() => {
  switch (props.type) {
    case 'expiredDate':
      return {
        name: 'lock',
        color: 'error',
      }
    case 'notFound':
      return {
        name: 'not-found',
        color: 'primary',
      }
    default:
      return {
        name: 'bug',
        color: 'error',
      }
  }
})

const notice = computed(() => {
  if (props.type === 'badRequest') {
    return props.retry ? t('error.tryAgain') : t('error.refresh')
  }

  return null
})

const onClick = () => {
  emit('retry')
}
</script>

<template>
  <div :class="$style.wrapper">
    <h1 :class="$style.title">
      <BaseIcon :name="icon.name" :color="icon.color" :class="$style.icon" />
      {{ error.title }}
    </h1>

    <div :class="$style.content">
      <p>{{ error.message }}</p>
      <p v-if="notice">{{ notice }}</p>

      <BaseButton
        v-if="retry"
        color="secondary"
        :class="$style.button"
        @click="onClick"
      >
        {{ t('common.tryAgain') }}
      </BaseButton>
    </div>

    <div :class="$style.contact">
      <p>
        {{ error.contact }}
        <a href="mailto:support@promtime.org">support@promtime.org</a>
      </p>
    </div>
  </div>
</template>

<style module lang="postcss">
.wrapper {
  font-size: 2.2rem;

  @media (max-width: 600px) {
    margin: var(--spacings-xlarge) auto var(--spacings-large);
  }
}
.icon {
  margin-right: var(--spacings-xsmall);
}
.title {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 2.8rem;
}

.content {
  line-height: 1.2;
  margin: var(--spacings-large) 0;

  & p {
    margin: var(--spacings-large) 0;
  }
}

.button {
  @media (max-width: 600px) {
    width: 100%;
  }
}

.contact {
  line-height: 1.2;

  & a {
    text-decoration: none;
    color: var(--global-color-primary);
  }
}
</style>
